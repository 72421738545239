import { AxiosError, AxiosResponse } from 'axios';
import { SingleProject } from '../state/home/home.types';

export interface GetStateInterface<T = any> {
  loading: boolean;
  hasData: boolean;
  error: AxiosResponse<Data> | AxiosError | null;
  data: T | null;
}

interface Data {
  statusCode: number;
  error: string;
  message: string;
}

export const GetState: GetStateInterface = {
  loading: false,
  error: null,
  data: null,
  hasData: false,
};

export interface PostStateInterface {
  loading: boolean;
}

export const PostState: PostStateInterface = {
  loading: false,
};

export const GetProjectState: GetStateInterface<SingleProject> = {
  loading: true,
  error: null,
  hasData: false,
  data: {
    _id: '',
    id: '',
    calibrationState: 0,
    isArchived: false,
    sets: [],
    identifier: '',
    unitType_id: {
      configs: [],
      createdAt: '',
      global: false,
      icon: '',
      id: '',
      maxStrength: {},
      minStrength: {},
      name: '',
      updatedAt: '',
      variants: [],
    },
    config: {
      name: '',
    },
    targetProperties: [],
    targetStrength: {
      _value: 0,
      units: [],
      toImperialFactor: 0,
    },
    date: '',
    scaleFactor: 0,
    // defaultVariant: {},
    advancedInputs: false,
    dynamicScaling: false,
    createdAt: '',
    updatedAt: '',
    device: [],
    castingDate: '',
    mixWeight: {
      depth: '',
      max: 0,
      min: 0,
      name: '',
      toImperialFactor: 0,
      tracked: false,
      units: [],
      _value: 0,
    },
    // isDefault: false,
    // _createdAt: '',
    // _updatedAt: '',
    // _testTotal: 0,
    createdBy: {
      email: '',
      firstName: '',
      lastName: '',
      id: '',
    },
  },
};
