import { CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  PatchUserPayloadInterface,
  PatchUserSettingPayloadInterface,
  PatchUserDevicePayloadInterface,
} from './settings.types';

export const patchUserAPI = (payload: PatchUserPayloadInterface, ct?: CancelToken) => {
  return api.patch(
    '/users',
    { user: payload },
    {
      cancelToken: ct,
    },
  );
};

export const patchUserSettingAPI = (
  payload: PatchUserSettingPayloadInterface,
  ct?: CancelToken,
) => {
  return api.patch(
    '/users/setting',
    { user: payload },
    {
      cancelToken: ct,
    },
  );
};

export const patchDevicesAPI = (payload: PatchUserDevicePayloadInterface, ct?: CancelToken) => {
  return api.patch('/users/device', payload, {
    cancelToken: ct,
  });
};
