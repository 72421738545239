export enum SettingActions {
  PATCH_USER = 'PATCH_USER',
}

export interface PatchUserPayloadInterface {
  avatar: string;
  firstName: string;
  lastName: string;
  displayUnit?: string;
}

export interface PatchUserSettingPayloadInterface {
  displayUnit?: string;
}

export interface PatchUserDevicePayloadInterface {
  deviceId?: string;
}
