import React, { useContext, useEffect, useState } from 'react';
import HamBurger from '../../components/navbar/hamBurger';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import FormButton from '../../components/shared/Button/formButton';
import AuthContext from '../../state/auth/auth.context';
import { AuthContextInterface, MeInterface } from '../../state/auth/auth.types';
import { compileMediaUrl, executeOnSuccess } from '../../utils/misc.utils';
import Save from '@material-ui/icons/SaveOutlined';
import Camera from '@material-ui/icons/CameraAltOutlined';
import Account from '@material-ui/icons/AccountCircleOutlined';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileForm, ProfileFormSchema } from '../../state/settings/forms/profile.form';
import { useHistory } from 'react-router-dom';
import { useUploadMedia } from '../../state/media/media.hook';
import Loader from '../../components/shared/Loader';
import classNames from 'classnames';
import { usePatchUser, usePatchDevices } from '../../state/settings/settings.hook';
import { CancelPresentation, SettingsCell } from '@material-ui/icons';
import { GoDeviceMobile } from 'react-icons/go';

import { Grid, Paper, Stack } from '@mui/material';
import { Typography } from '@material-ui/core';

const Devices: React.FC<any> = (props) => {
  const history = useHistory();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { me, setMe } = useContext<AuthContextInterface>(AuthContext);
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(ProfileFormSchema),
  });
  const [avatar, setAvatar] = useState(me?.avatar);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deviceList, setDeviceList] = useState(props.devices);
  const { loading: uploading, data, uploadSingleMedia: uploadPhoto } = useUploadMedia();
  const { loading: saving, data: savedData, patchUser } = usePatchUser();
  const { loading: savingDevice, data: savedDataDevice, patchDevices } = usePatchDevices();
  const [currentDevice, setCurrentDevice] = useState<any>(null);

  useEffect(() => {
    executeOnSuccess(uploading, data, () => setAvatar(encodeURIComponent(data?.name)));
  }, [uploading, data]);

  useEffect(() => {
    executeOnSuccess(saving, savedData, () => {
      setMe({
        ...(me as MeInterface),
        firstName: savedData?.user?.firstName || me?.firstName,
        lastName: savedData?.user?.lastName || me?.lastName,
        avatar: savedData?.user?.avatar || me?.avatar,
      });
      history.push('/');
    });
  }, [saving, savedData]);

  useEffect(() => {
    executeOnSuccess(savingDevice, savedDataDevice, () => {
      window.location.reload();
    });
  }, [savingDevice, savedDataDevice]);

  useEffect(() => {
    setValue(ProfileForm.FIRST_NAME, me?.firstName, { shouldValidate: true });
    setValue(ProfileForm.LAST_NAME, me?.lastName, { shouldValidate: true });
    setAvatar(me?.avatar);
  }, [me, setValue]);

  const goBack = () => {
    if (history.action === 'PUSH' && history.goBack) history.goBack();
    else history.push('/');
  };

  const onUpload = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      uploadPhoto({ name: file.name, data: file });
    }
  };

  const onSave = (data: any, e: any) => {
    e.preventDefault();
    patchUser({
      ...data,
      avatar,
    });
  };

  const openConfirmation = (row: any) => {
    setCurrentDevice(row);
    setShowConfirmation(true);
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const deleteDevice = () => {
    patchDevices({
      deviceId: currentDevice.deviceID,
    });
  };

  // _id: string;
  // deviceID: string;
  // pltaformVersion: string;
  // platform: string;
  // appVersion: string;
  // deviceName: string;

  return (
    <div className="main--content">
      <div className="main--canvas">
        {/* <HamBurger /> */}
        <div className="setting--head mb-40">
          <span className="settingHead__icon d-inline-flex mr-3">
            <SettingsCell />
          </span>
          <span className="settingHead__text">Devices</span>
        </div>
        <Form>
          <div className="device_block_main">
            <div>
              {/* <Row className="row--space-10"> */}
              <Grid container spacing={2}>
                {deviceList.map((row: any, index: any) => (
                  <Grid item xs={4}>
                    <Paper>
                      <Grid container direction="row" sx={{ p: 2 }}>
                        <Grid item sx={{ mr: 1 }}>
                          <GoDeviceMobile size={60} />
                        </Grid>
                        <Grid sx={{ pl: 1 }} item>
                          <Stack direction="column">
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography color="textSecondary" variant="body1">
                                  Device Name:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography> {row.deviceName}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography color="textSecondary" variant="body1">
                                  Device ID:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography> {row.deviceID}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography color="textSecondary" variant="body1">
                                  Platform version:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography> {row.pltaformVersion}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography color="textSecondary" variant="body1">
                                  Platform:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography> {row.platform}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography color="textSecondary" variant="body1">
                                  App version:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography> {row.appVersion}</Typography>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  //  <Col xs={12} sm={4} className="mt-3">
                  //     <div className="device_block">
                  //       <Row className="row--space-10">
                  //         <Col xs={12} sm={12}>
                  //           {/* <CancelPresentation
                  //             style={{ cursor: 'pointer' }}
                  //             onClick={() => openConfirmation(row)}
                  //             className="close_icon"
                  //           /> */}
                  //           {/* <img
                  //             src="images/device.jpg"
                  //             alt="Logo"
                  //             width="80"
                  //             className="device_image"
                  //           /> */}
                  //           <GoDeviceMobile size={70} />
                  //           <h4 className="device_text text_center">
                  //             <br />
                  //             {row.platform}
                  //           </h4>
                  //         </Col>
                  //       </Row>
                  //       <Row className="row--space-10">
                  //         <Col xs={12} sm={12} className="text_center mt-3">
                  //           {/* <TextField
                  //             id="outlined-basic"
                  //             className="text_center pt-2 device_name"
                  //             placeholder={row.deviceName}
                  //             value={row.deviceName}
                  //             variant="outlined"
                  //           /> */}
                  //           <p className="text_center mt-1 device_id">{row.deviceID}</p>
                  //         </Col>
                  //       </Row>
                  //     </div>
                  //   </Col>
                ))}
              </Grid>
              {/* </Row> */}
            </div>
          </div>
        </Form>
      </div>
      <Modal
        show={showConfirmation}
        onHide={handleClose}
        className="modal-settings set--modal"
        contained-modal-title-vcenter="true"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this device? All data associated with this device will be
          removed from our servers.
          <Col xs={12}>
            <Row className="row--space-10 flex-sm-row-reverse pt-sm-4 mb-4 pb-2">
              <Col xs={12} sm={6}>
                <FormButton
                  className="app__btn mb-4 mb-sm-0"
                  text="Confirm"
                  loadingText="Changing"
                  onClick={() => deleteDevice()}
                />
              </Col>
              <Col xs={12} sm={6}>
                <FormButton
                  type="button"
                  className="app__btn outline__btn"
                  text="Cancel"
                  onClick={() => handleClose()}
                />
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Devices;
