import { uploadMediaAPI } from './media.api';
import { GetState } from '../../types/GetStateInterface';
import { useState } from 'react';
import { MediaActions, UploadMediaInterface } from './media.types';
import { callCT, setCT } from '../../utils/api.utils';
import axios from 'axios';

export const useUploadMedia = () => {
  const [res, setRes] = useState(GetState);
  const [progress, setProgress] = useState({ progressBar: 0, name: '' });
  const actionName = MediaActions.UPLOAD_MEDIA;

  const uploadMedia = async (media: UploadMediaInterface[]) => {
    if (media && media.length) {
      const data = new FormData();
      for (const img of media) {
        data.append('file', img.data);
      }
      setRes((prevState) => ({ ...prevState, loading: true }));
      callCT(actionName);
      try {
        const res = await uploadMediaAPI(data, undefined, setCT(actionName));
        setRes({
          data: res.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          setRes({ data: null, loading: false, error, hasData: true });
        }
      }
    }
  };

  const uploadSingleMedia = async (media: UploadMediaInterface, needNameOnError?: boolean) => {
    if (media) {
      const data = new FormData();
      data.append('file', media.data);
      setRes((prevState) => ({ ...prevState, loading: true }));
      callCT(actionName);
      try {
        const res = await uploadMediaAPI(
          data,
          (e: number) => setProgress({ name: media.name, progressBar: e }),
          setCT(actionName),
        );
        setRes({
          data: res.data?.[0],
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          setRes({
            data: needNameOnError ? { name: media.name } : null,
            loading: false,
            error,
            hasData: true,
          });
        }
      }
    }
  };

  return { progress, ...res, uploadMedia, uploadSingleMedia };
};
