import { ReactNode } from 'react';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Search from '@material-ui/icons/Search';
import Dashboard from '@material-ui/icons/Dashboard';
import Routes from './routes';
import { Group } from '@material-ui/icons';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface Option {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
  path: string;
}

export const TOKEN_KEY = 'token';
export const TOKEN_KEY_SUPER = 'token_super';

export enum ROLES {
  SUPER_USER = 1,
  ADMIN = 2,
  USER = 3,
}

export const AccountOptions: Option[] = [
  {
    text: 'My Profile',
    icon: AccountCircle,
    ...Routes.profilepage,
  },
  // {
  //   text: 'Settings',
  //   icon: Settings,
  //   ...Routes.settings,
  // },
  {
    text: 'Sign Out',
    icon: ExitToApp,
    ...Routes.logout,
  },
];

export const NavigationOptions: Option[] = [
  {
    text: 'Home',
    icon: Dashboard,
    ...Routes.home,
  },
  {
    text: 'User',
    icon: Group,
    ...Routes.user,
  },
];

export const GET_ALL_PROJECTS_DEFAULT_LIMIT = 10;
