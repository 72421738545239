import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Loader from '../Loader';
import { Button } from 'react-bootstrap';

interface Props {
  loading?: boolean;
  type?: string;
  className?: string;
  text: string;
  loadingText?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

const FormButton: React.FC<Props> = forwardRef<HTMLButtonElement, Props>(
  ({ loading, type, className, text, loadingText, disabled, onClick }: Props, ref) => {
    return (
      <Button
        ref={ref}
        type={type || 'submit'}
        className={classNames({
          'w-100': true,
          [className || 'form__btn']: true,
        })}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? (
          <React.Fragment>
            {loadingText || 'Loading'}
            <Loader animation="grow" size="sm" className="ml-1" />
          </React.Fragment>
        ) : (
          text
        )}
      </Button>
    );
  },
);

export default FormButton;
