import { patchUserAPI, patchUserSettingAPI, patchDevicesAPI } from './settings.api';
import { useCallback, useState } from 'react';
import {
  PatchUserPayloadInterface,
  PatchUserSettingPayloadInterface,
  PatchUserDevicePayloadInterface,
  SettingActions,
} from './settings.types';
import { GetState } from '../../types/GetStateInterface';
import axios from 'axios';
import { callCT, setCT } from '../../utils/api.utils';

export const usePatchUser = () => {
  const [res, setRes] = useState(GetState);

  const patchUser = useCallback(async (payload: PatchUserPayloadInterface) => {
    const actionName = SettingActions.PATCH_USER;
    callCT(actionName);
    setRes((prevState) => ({ ...prevState, loading: true }));
    try {
      const res = await patchUserAPI(payload, setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { ...res, patchUser };
};

export const usePatchUserSetting = () => {
  const [res, setRes] = useState(GetState);

  const patchUserSetting = useCallback(async (payload: PatchUserSettingPayloadInterface) => {
    const actionName = SettingActions.PATCH_USER;
    callCT(actionName);
    setRes((prevState) => ({ ...prevState, loading: true }));
    try {
      const res = await patchUserSettingAPI(payload, setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { ...res, patchUserSetting };
};

export const usePatchDevices = () => {
  const [res, setRes] = useState(GetState);

  const patchDevices = useCallback(async (payload: PatchUserDevicePayloadInterface) => {
    const actionName = SettingActions.PATCH_USER;
    callCT(actionName);
    setRes((prevState) => ({ ...prevState, loading: true }));
    try {
      const res = await patchDevicesAPI(payload, setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { ...res, patchDevices };
};
