export enum MediaActions {
  UPLOAD_MEDIA = 'UPLOAD_MEDIA',
}

export interface UploadMediaInterface {
  data: any;
  dataView?: any;
  name: string;
}

export interface UploadedMediaInterface {
  _id?: string;
  name: string;
  url: string;
  type?: string;
}
