import React from 'react';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { Variant } from 'react-bootstrap/types';

interface Props {
  page?: boolean;
  parent?: boolean;
  className?: string;
  animation?: 'border' | 'grow';
  role?: string;
  size?: 'sm';
  variant?: Variant;
  spinnerClass?: string;
}

const Loader: React.FC<Props> = ({
  page,
  parent,
  className,
  variant,
  size,
  animation = 'border',
  role = 'status',
  spinnerClass,
}) => {
  return (
    <div
      className={classNames({
        [className || '']: true,
        'spinner--page': page,
        'd-inline-flex': !page && !parent,
        'd-flex h-100 w-100': parent,
        'align-items-center justify-content-center': !page,
      })}
    >
      <Spinner
        animation={animation}
        variant={variant}
        role={role}
        size={size}
        className={classNames({
          [spinnerClass || '']: true,
        })}
      />
    </div>
  );
};

Loader.defaultProps = {
  animation: 'border',
};

export default Loader;
