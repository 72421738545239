import axios, { CancelToken, Canceler } from 'axios';
import env from '../utils/env.utils';

const cancellationTokens: Record<string, Canceler> = {};

export const setCT = (actionName: string): CancelToken => {
  return new axios.CancelToken((cancel: Canceler) => (cancellationTokens[actionName] = cancel));
};

export const callCT = (actionName: string, message?: string): void => {
  return cancellationTokens?.[actionName]?.(message);
};

const api = axios.create({
  baseURL: env.apiUrl,
  withCredentials: true,
});

export default api;
