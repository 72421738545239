import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import { UploadedMediaInterface } from './media.types';

export const uploadMediaAPI = (
  media: FormData,
  handleProgress?: (progress: number) => void,
  ct?: CancelToken,
): AxiosPromise<UploadedMediaInterface[]> => {
  return api.post('/media', media, {
    cancelToken: ct,
    onUploadProgress: (progressEvent) => {
      if (progressEvent?.lengthComputable) {
        return handleProgress?.(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    },
  });
};
