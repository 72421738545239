import React from 'react';

const NetworkError: React.FC = () => {
  return (
    <div className="notFound--page">
      <div className="container--sm text-center animation-fadeIn">
        <div className="text-center">
          <a className="form__logo d-inline-flex mb-4 pb-3">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="380px"
              height="270px"
              viewBox="25.5 0 445 418.5"
              enableBackground="new 25.5 0 445 418.5"
              xmlSpace="preserve"
            >
              <path
                fill="#FFCD15"
                d="M372.06,363.124H127.94c-24.58,0-43.116-8.672-52.208-24.412c-9.092-15.752-7.328-36.144,4.96-57.424
	              l122.06-211.404c12.28-21.284,29.064-33.004,47.244-33.004c18.18,0,34.964,11.72,47.244,33.004L419.3,281.288
	              c12.288,21.28,14.052,41.672,4.96,57.424C415.172,354.452,396.636,363.124,372.06,363.124z M274.908,288.721
	              c0-12.688-10.288-22.977-22.977-22.977c-12.688,0-22.976,10.288-22.976,22.977c0,12.688,10.288,22.975,22.976,22.975
	              C264.62,311.695,274.908,301.408,274.908,288.721z M275.527,150.452c0-13.028-10.563-23.592-23.592-23.592
	              c-13.027,0-23.591,10.564-23.591,23.592l5.572,83.728h0.076c0.808,9.248,8.484,16.528,17.943,16.528
	              c8.748,0,16.049-6.204,17.736-14.452c0.137-0.668,0.148-1.38,0.208-2.072h0.16L275.527,150.452z"
              />
            </svg>
          </a>
        </div>
        <h3 className="form__heading text-center mb-3">
          Oops, your internet connection seems off...
        </h3>
        <p className="para__md d-flex flex-column align-items-center mb-3 pb-2">
          <span className="d-inline-block mb-1">
            Please check your internet connection and{' '}
            <a onClick={() => window?.location?.reload()} className="form__link">
              reload
            </a>
            .
          </span>
        </p>
      </div>
    </div>
  );
};

export default NetworkError;
