import * as yup from 'yup';

export enum ProfileForm {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DISPLAY_UNIT = 'displayUnit',
}

export const ProfileFormSchema = yup.object().shape({
  [ProfileForm.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [ProfileForm.LAST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
});

export const ProfileFormSettingSchema = yup.object().shape({});
